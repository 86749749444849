import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { AppInsights } from 'applicationinsights-js';
import { Angulartics2AppInsights } from 'angulartics2/appinsights';
import { Angulartics2 } from 'angulartics2';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ProfileService } from './profile.service';
import { maskEmail } from '../../../utils/formatters';
import { TranslateService } from '@ngx-translate/core';
import { PartnerService } from './partner.service';
import { MarketplacePartnerAffiliationType } from '../enums/marketplace-partner-affiliation-types';
import { filter, take } from 'rxjs/operators';
import { AnalyticsCustomEvents } from '../enums/analytics-custom-events';
import { MixPanelService } from './mix-panel.service';

const applicationInsights = new ApplicationInsights({
  config: {
    instrumentationKey: `${environment.instrumentationKey}`
  }
});

@Injectable()
export class AnalyticsService extends Angulartics2AppInsights {
  public subscriptions = new Array<Subscription>();
  events: any[] = [];
  dimensions = {};

  get email() { return maskEmail(this.profileService.person ? this.profileService.person.email : ''); }

  constructor(
    public analytics: Angulartics2,
    private documentTitle: Title,
    private route: Router,
    private profileService: ProfileService,
    private translateService: TranslateService,
    private partnerService: PartnerService,
    private mixPanel: MixPanelService
  ) {
    super(analytics, documentTitle, route);
    if (!AppInsights.config) {
      AppInsights.downloadAndSetup(applicationInsights.config);
    }

    this.mixPanel.reset();
    this.profileService.personLoadedObs$.pipe(
      filter(loaded => loaded),
      take(1)
    )
      .subscribe(() => {
        this.analytics.settings.developerMode = this.analytics.settings.developerMode || this.profileService.person.disableAnalytics;
        this.mixPanel.initializeMixPanel(this.profileService.person);
      });
  }

  /**
   * Helper method to disable analytics
   */
  disableAnalytics() {
    this.analytics.settings.developerMode = true;
  }

  /**
   * start tracking time of a custom event
   *
   * @param eventName Name of the event
   */
  startCustomTracking(eventName: string) {
    this.events.push({
      event: eventName,
      startTime: new Date()
    });
  }
  /**
   * pushes into list of resume times for a custom event
   * or invokes start if event is not present
   *
   * @param eventName Name of the event
   */
  resumeCustomTracking(eventName: string) {
    const event = this.events.find(x => x.event === eventName);
    if (event != null) {
      event['resumeTimes'] = event['resumeTimes'] || [];
      const pausedTimes = event.pauseTimes ? event.pauseTimes.length : 0;
      if (event.resumeTimes.length < pausedTimes) {
        event['resumeTimes'].push(new Date());
      }
    } else {
      this.startCustomTracking(eventName);
    }
  }
  /**
   * pushes into list of pause times for a custom event
   *
   * @param eventName Name of the event
   */
  pauseCustomTracking(eventName: string) {
    const event = this.events.find(x => x.event === eventName);
    const stamp = new Date();
    if (event != null) {
      event['pauseTimes'] = event['pauseTimes'] || [];
      const resumedTimes = event.resumeTimes ? event.resumeTimes.length : 0;
      if (event.pauseTimes.length === resumedTimes) {
        event['pauseTimes'].push(stamp);
        event['stopTime'] = stamp;
      }
    }
  }
  /**
   * stop tracking time of custom event
   *
   * @param eventName Name of the event
   */
  stopCustomTracking(eventName: string) {
    const event = this.events.find(x => x.event === eventName);
    if (event != null) {
      event['stopTime'] = new Date();
    }
  }
  /**
   * removes from list of current events the given events
   *
   * @param eventNames array of event names to remove
   */
  disposeCustomTracking(eventNames: string[]) {
    this.events = this.events.filter(x => !eventNames.includes(x.event));
  }
  /**
   * get tracked time of the custom event
   *
   * @param eventName Name of the event
   * @param includePausedTime wheter to include paused times in calculation
   */
  getTrackedTime(eventName: string, includePausedTime = true) {
    const event = this.events.find(x => x.event === eventName);
    let pausedTime = 0;
    if (event != null) {
      const totalTime = +event.stopTime - +event.startTime;
      if (event.pauseTimes !== undefined) {
        event.pauseTimes.forEach((element, index) => {
          if (event.resumeTimes !== undefined && event.resumeTimes[index] !== undefined
            && element !== undefined && +event.resumeTimes[index] > +element) {
            pausedTime += +event.resumeTimes[index] - +element;
          }
        });
      }
      if (includePausedTime) {
        return totalTime.toString();
      } else {
        return (totalTime - pausedTime).toString();
      }
    } else {
      return '0';
    }
  }
  /**
   * get non contiguous tracked time segments of the custom event
   *
   * @param eventName Name of the event
   */
  getNonContiguousTrackedTimes(eventName: string) {
    const event = this.events.find(x => x.event === eventName);
    const times: string[] = [];
    if (event != null) {
      if (event.pauseTimes !== undefined && event.resumeTimes !== undefined
        && event.pauseTimes.length > 0 && event.resumeTimes.length > 0) {
        times.push((+event.pauseTimes[0] - +event.startTime).toString());
        event.pauseTimes.forEach((element, index) => {
          // skip element 0 of paused times since computed above
          if (index - 1 >= 0 && event.resumeTimes[index - 1] !== undefined
            && element !== undefined && +event.resumeTimes[index - 1] < +element) {
            times.push((+element - +event.resumeTimes[index - 1]).toString());
          }
        });
      } else {
        times.push((+event.stopTime - +event.startTime).toString());
      }
    }
    return times;
  }
  /**
   * generate correlationId to relate custom events
   */
  generateCorrelationId() {
    return '_' + Math.random().toString(36).substr(2, 9);
  }

  /**
   * Override NgxAnalyticsAppInsights pageTrack method to track page title
   */
  pageTrack(path: string) {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach(s => s.unsubscribe());
    }

    if (this.route.routerState) {
      this.trackPageTitle();
    } else {
      const sub = this.route.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.trackPageTitle(event.url);
        }
      });
      this.subscriptions.push(sub);
    }
  }

  /**
   * Track active subscription products
   */
  trackActiveProducts() {
    this.route.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.productActiveOnDashboard(event.url);
      }
    });
  }

  sendProductActiveEvent(product: string) {
    this.analytics.eventTrack.next({
      action: `ProductActive-${product}`,
      properties: { Email: this.email }
    });
  }

  /**
   * Log custom event that product is active on dashboard visit
   *
   * @param url path user got redirected to
   */
  private productActiveOnDashboard(url?: string) {
    let product = '';
    let partner = MarketplacePartnerAffiliationType.None;
    if (url.includes('shipping/dashboard')) {
      product = 'Shipping';
    } else if (url.includes('appraisals/dashboard') || url.endsWith('/appraisals')) {
      product = 'Appraisal';
    } else if (url.includes('marketplace/dashboard') || url.endsWith('/marketplace')) {
      product = 'Marketplace';
      // eslint-disable-next-line max-len
      partner = this.profileService.person ? this.partnerService.getSelectedPartner(this.profileService.person) : MarketplacePartnerAffiliationType.None;
    } else if (url.includes('studio/dashboard') || url.endsWith('/studio')) {
      product = 'Studio';
    } else if (url.includes('policy/business/dashboard') || url.endsWith('policy/business')) {
      product = 'BusinessInsurance';
    } else if (url.includes('memo/dashboard')) {
      product = 'Memo';
    } else if (url.includes('jewelerpages/dashboard') || url.endsWith('/jewelerpages')) {
      product = 'JewelerPages';
    } else if (url === '/dashboard') {
      product = 'ZingDashboard';
    } else {
      return;
    }
    this.sendProductActiveEvent(product);

    if (partner === MarketplacePartnerAffiliationType.AGS) {
      this.analytics.eventTrack.next({
        action: `PartnerActive-${MarketplacePartnerAffiliationType[partner]}`,
        properties: { Email: this.email }
      });

      this.mixPanel.sendTrackingRequest(
        `PartnerActive-${MarketplacePartnerAffiliationType[partner]}`,
        { Email: this.email });
    }
  }

  private trackPageTitle(url?: string) {
    if (this.dimensions && (!this.dimensions['Email'] || this.dimensions['Email'] !== this.email)) {
      this.dimensions['Email'] = this.email;
    }
    const title = this.getTitle(this.route.routerState, this.route.routerState.root).join('-');
    appInsights.trackPageView(
      title,
      url,
      this.dimensions,
      this.metrics,
      this.loadTime
    );
    this.mixPanel.sendTrackingRequest(
      title,
      {
        url,
        'dimensions': this.dimensions,
        'metrics': this.metrics,
        'loadTime': this.loadTime
      });
  }

  private getTitle(state, parent) {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(this.translate(parent.snapshot.data.title));
    }

    if (state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  /**
   * If we are using translation for page title use
   * translate service to get the title
   *
   * @param title Page title
   * @returns Translated page title
   */
  private translate(title: string): string {
    if (title.startsWith('Titles.')) {
      title = this.translateService.instant(title);
    }
    return title;
  }

  public setAnalyticsEvent(analyticsCustomEvents: AnalyticsCustomEvents, properties) {
    this.analytics.eventTrack.next({
      action: analyticsCustomEvents,
      properties
    });
    this.mixPanel.sendTrackingRequest(
      analyticsCustomEvents,
      properties);
  }

  public sendTrackingRequest(eventName: string, eventObject: object) {
    this.mixPanel.sendTrackingRequest(
      eventName, eventObject);
  }
}
