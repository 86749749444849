import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { environment } from '../../../../environments/environment';
import { Person } from '../../membership/modules/account/models/person.model';

@Injectable({
  providedIn: 'root'
})
export class MixPanelService {
  constructor() {
    mixpanel.init(environment.mixPanelApiKey, {
      debug: true,
      persistence: 'localStorage',
    });
  }

  sendTrackingRequest(eventName: string, eventObject: object) {
    mixpanel.track(
      eventName, eventObject
    );
  }

  reset() {
    mixpanel.reset();
  }

  initializeMixPanel(person: Person) {
    const referenceId = person?.referenceId;
    mixpanel.identify(referenceId);
    mixpanel.people.set({
      $authenticatedId: referenceId,
      $companyId: person?.companyId,
      $registrationId: person?.registrationId
    });
  }
}
