export function search(items: any[] = [], searchString: string = ''): any[] {
  if (!items) { return []; }
  // Replaces multiple whitespaces, new lines and tabs with a single whitespace
  searchString = searchString.replace(/\s\s+/g, ' ');
  const searchStringParts = searchString.split(' ');
  return items.filter(item => {
    const values = getValues(item).join();
    return searchStringParts.every(part => values.toLowerCase().includes(part.toLowerCase()));
  });
}

/** Extracts all values of given object */
function getValues(obj: Object = {}) {
  const values = [];
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      values.push(getValues(obj[key]));
    } else {
      if (typeof obj[key] !== 'function') {
        values.push(obj[key]);
      }
    }
  }
  return values;
}

export function compareCaseInsensitive(string1: string, string2: string): boolean {
  // if both values are empty, strings are equal
  if (!string1 && !string2) { return true; }
  if (!string1 || !string2) { return false; }
  return string1.toLowerCase().trim() === string2.toLowerCase().trim();
}

export function startsWithSearchFunction(term: string, item: any) {
  return item.value.toLocaleLowerCase().startsWith(term.toLocaleLowerCase());
}
