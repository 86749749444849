import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'z-pdf-view.',
  templateUrl: './pdf-view..component.html',
  styleUrls: ['./pdf-view..component.scss'],
  standalone: true,
  imports: [
    TranslateModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdfViewComponent implements OnInit {
  title: string;
  sanitizedUrl: any;

  constructor(
    private route: ActivatedRoute,
    private domSanitizer: DomSanitizer,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    const documentTitle = this.route.snapshot.data['documentTitle'];
    this.title = this.translateService.instant(documentTitle);
    const documentUrl = this.route.snapshot.data['documentUrl'];
    this.sanitizedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(documentUrl);
  }
}
